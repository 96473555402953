<template>
  <section class="account-profile account__body-inner">
    <p
      v-if="profileCompletionPercentage !== 100"
      class="account-profile__heading"
    >
      Hubbell strongly recommends completing your profile so we can deliver
      relevant product content, promotions, and a personalized experience
    </p>
    <HintBar
      v-if="isProfileUpdated"
      :hintData="hintProfileUpdated"
      class="account-profile__hintbar"
    />
    <HintBar
      v-if="showResetPasswordHint"
      :key="hintResetPassword.message"
      :hintData="hintResetPassword"
      class="account-profile__hintbar"
    />

    <form class="account-profile__form" @submit.prevent="saveProfile">
      <ul class="account-profile__list">
        <li class="account-profile__item">
          <FormGroup
            v-model="$v.form.firstName.$model.value"
            @input="$emit('fieldChanged', form.firstName)"
            :field="$v.form.firstName"
            :isValidationRun="isValidationRun"
          />
        </li>
        <li class="account-profile__item">
          <FormGroup
            v-model="$v.form.lastName.$model.value"
            @input="$emit('fieldChanged', form.lastName)"
            :field="$v.form.lastName"
            :isValidationRun="isValidationRun"
          />
        </li>
        <li class="account-profile__item">
          <FormGroup
            v-model="$v.form.company.$model.value"
            @input="$emit('fieldChanged', form.company)"
            :field="$v.form.company"
            :isValidationRun="isValidationRun"
          />
        </li>
        <li class="account-profile__item">
          <FormGroup
            v-model="$v.form.phone.$model.value"
            @input="$emit('fieldChanged', form.phone)"
            :field="$v.form.phone"
            :isValidationRun="isValidationRun"
          />
        </li>
        <li class="account-profile__item">
          <FormSelect
            v-model="form.productInterests.value"
            :field="form.productInterests"
            :isValidationRun="isValidationRun"
            @input="$emit('fieldChanged', form.productInterests)"
          />
        </li>
        <li class="account-profile__item">
          <FormSelect
            v-model="form.customerRole.value"
            @input="$emit('fieldChanged', form.customerRole)"
            :field="form.customerRole"
            :isValidationRun="isValidationRun"
          />
        </li>
        <li class="account-profile__item">
          <div class="account-profile__item-inner">
            <FormGroup v-model="form.email.value" :field="form.email" />
            <p class="account-profile__field-hint" v-if="!isEmployee">
              The login for store.hubbell.com is managed via your Hubbell ID;
              <a
                @click.prevent="doResetPassword"
                href="/"
                class="link"
              >click here to change your Hubbell ID password</a>
            </p>
          </div>
        </li>
        <li v-if="showFriendsAndFamilyCode" class="account-profile__item"></li>
        <li v-if="showFriendsAndFamilyCode" class="account-profile__item account-profile__item--vertical">
          <FormGroup
            v-model="form.friendsAndFamilyCode.value"
            :field="form.friendsAndFamilyCode"
            :class="{'form-group--error': showFriendsAndFamilyCodeError}"
          />
          <span
            v-if="showFriendsAndFamilyCodeError"
            v-html="form.friendsAndFamilyCode.errors.required"
            class="form-group__hint"
          ></span>
        </li>
      </ul>
      <button
        :disabled="isLoading"
        type="submit"
        class="button button--primary account-profile__btn-save"
      >
        Save
      </button>
    </form>

    <loader v-if="isLoading"/>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import settings from '@/settings'
import { required, maxLength, numeric, minLength } from 'vuelidate/lib/validators'
import LoaderMixin from '@/mixins/LoaderMixin'
import FormValidationMixin from '@/mixins/FormValidationMixin'
import FormGroup from '@/components/FormGroup'
import FormSelect from '@/components/FormSelect'
import HintBar from '@/components/HintBar'

export default {
  name: 'AccountProfile',
  components: {
    FormGroup,
    FormSelect,
    HintBar
  },
  mixins: [LoaderMixin, FormValidationMixin],
  computed: {
    ...mapState('user', ['userData']),
    ...mapGetters('user', ['profileCompletionPercentage', 'isEmployee']),
    ...mapGetters('app', ['isUSSite']),
    showFriendsAndFamilyCode () {
      return !this.isEmployee && this.isUSSite
    }
  },
  methods: {
    ...mapActions('user', ['updateUserData', 'applyInvitationsCode']),
    ...mapActions('app', ['doResetPassword']),
    saveProfile () {
      this.withPresendRoutine(async () => {
        this.showFriendsAndFamilyCodeError = false
        this.isProfileUpdated = false
        const formData = {}
        this.pushFormToData(formData)
        delete formData.friendsAndFamilyCode

        this.isLoading = true
        if (this.showFriendsAndFamilyCode && !this.userData.friendsAndFamilyCode && this.form.friendsAndFamilyCode.value) {
          const isValid = await this.applyInvitationsCode(this.form.friendsAndFamilyCode.value)
          this.showFriendsAndFamilyCodeError = !isValid
          if (!isValid) {
            this.isLoading = false
            return
          }
          this.updateFriendsAndFamilyCodeField()
        }
        this.sendForm(this.updateUserData, formData)
          .then(() => {
            this.isProfileUpdated = true
          }).catch(() => {
            this.isValidationRun = true
          }).finally(() => {
            this.isLoading = false
          })
      })
    },
    updateFriendsAndFamilyCodeField () {
      if (!this.isEmployee && this.userData.friendsAndFamilyCode && this.isUSSite) {
        this.form.friendsAndFamilyCode.value = `Activated via code ${this.userData.friendsAndFamilyCode}`
        this.form.friendsAndFamilyCode.disabled = true
      }
    }
  },
  created () {
    this.pushDataToForm(this.userData)
    this.updateFriendsAndFamilyCodeField()
    const query = { ...this.$route.query }
    const isResetInitiated = !!query[settings.app.queryTokens.resetPasswordInitiatedToken]
    const isResetCanceled = !!query[settings.app.queryTokens.resetPasswordCanceledToken]

    if (isResetInitiated) {
      if (!isResetCanceled) this.showResetPasswordHint = true
      delete query[settings.app.queryTokens.resetPasswordInitiatedToken]
      delete query[settings.app.queryTokens.resetPasswordCanceledToken]
      this.$router.replace({ query })
    }
  },
  updated () {
    if (this.showResetPasswordHint) this.showResetPasswordHint = false
  },
  validations () {
    return {
      form: {
        firstName: {
          value: {
            required,
            maxLength: maxLength(35),
            server: this.server
          }
        },
        lastName: {
          value: {
            required,
            maxLength: maxLength(35),
            server: this.server
          }
        },
        company: {
          value: {
            maxLength: maxLength(35),
            server: this.server
          }
        },
        phone: {
          value: {
            numeric,
            maxLength: maxLength(10),
            minLength: this.form.phone.value ? minLength(10) : true,
            server: this.server
          }
        }
      }
    }
  },
  data () {
    return {
      isValidationRun: false,
      isProfileUpdated: false,
      showResetPasswordHint: false,
      showFriendsAndFamilyCodeError: false,
      hintResetPassword: {
        variant: 'success',
        iconColor: 'success',
        message: 'Your password has been updated.'
      },
      form: {
        firstName: {
          placeholder: 'First Name *',
          value: '',
          defaultValue: '',
          title: 'firstName',
          type: 'text',
          variant: 'shadow',
          maxLength: '35',
          errors: {
            required: 'Please enter a First Name',
            minLength: 'Please provide an alternate name with minimum length of 2 characters'
          }
        },
        lastName: {
          placeholder: 'Last Name *',
          value: '',
          defaultValue: '',
          title: 'lastName',
          type: 'text',
          variant: 'shadow',
          maxLength: '35',
          errors: {
            required: 'Please enter a Last Name',
            minLength: 'Please provide an alternate name with minimum length of 2 characters'
          }
        },
        company: {
          placeholder: 'Company',
          value: '',
          defaultValue: '',
          title: 'companyName',
          type: 'text',
          variant: 'shadow',
          maxLength: '35',
          errors: {
            required: 'Please enter a Company',
            minLength: 'Please provide an alternate name with minimum length of 2 characters',
            maxLength: 'This field must to be between 0 and 35 characters long'
          }
        },
        phone: {
          inputType: 'the-mask',
          placeholder: 'Phone Number',
          value: '',
          defaultValue: '',
          title: 'contactNumber',
          type: 'tel',
          mask: '###-###-####',
          variant: 'shadow',
          errors: {
            numeric: 'Please enter a 10-digit Phone Number',
            minLength: 'Please enter a 10-digit Phone Number'
          }
        },
        productInterests: {
          type: 'select',
          options: [
            {
              name: ' ',
              code: ''
            },
            {
              name: 'Industrial',
              code: 'industrial'
            },
            {
              name: 'Commercial',
              code: 'commercial'
            },
            {
              name: 'Residential',
              code: 'residential'
            }
          ],
          titleField: 'name',
          valueField: 'code',
          value: null,
          title: 'productInterests',
          variant: 'shadow',
          placeholder: 'Product interests',
          errors: {
            required: 'Please enter a Product Interests'
          }
        },
        customerRole: {
          type: 'select',
          options: [
            {
              name: ' ',
              code: ''
            },
            {
              name: 'Contractor',
              code: 'contractor'
            },
            {
              name: 'Engineer',
              code: 'engineer'
            },
            {
              name: 'Homeowner',
              code: 'homeowner'
            }
          ],
          titleField: 'name',
          valueField: 'code',
          value: null,
          title: 'customerRole',
          variant: 'shadow',
          placeholder: 'Role',
          errors: {
            required: 'Please enter a Role'
          }
        },
        email: {
          placeholder: 'Email Address',
          value: '',
          defaultValue: '',
          title: 'uid',
          type: 'email',
          disabled: true,
          variant: 'shadow',
          maxLength: '255'
        },
        friendsAndFamilyCode: {
          placeholder: 'Friends &amp; Family code',
          value: '',
          defaultValue: '',
          title: 'friendsAndFamilyCode',
          type: 'text',
          disabled: false,
          variant: 'shadow',
          maxLength: '255',
          errors: {
            required: 'The entered code is not valid'
          }
        }
      },
      hintProfileUpdated: {
        variant: 'success',
        iconColor: 'success',
        message: 'Your profile has been updated.'
      }
    }
  }
}
</script>
