<template>
  <section class="account-invite-friends account__body-inner">
    <h2 class="text-h4 account-invite-friends__title">Welcome to Hubbell's Friends &amp; Family Program!</h2>
  <p class="account-invite-friends__description">Invite someone to the Hubbell Store Friends &amp; Family Program!
    This will allow them to shop at a discounted price. Choose one of the options below to get started</p>
  <h2 class="text-h4 account-invite-friends__title">Enter their email below</h2>
  <div class="account-invite-friends__form-box">
    <HintBar
      v-if="showEmailSentHint"
      :hintData="hintEmailSent"
      class="account-invite-friends__hintbar"
    />
    <form class="account-invite-friends__form" @submit.prevent="sendInvite">
      <div class="account-invite-friends__form-fields">
        <FormGroup
          v-model="$v.form.email.$model.value"
          :field="$v.form.email"
          :isValidationRun="isValidationRun"
        />
        <span
          v-if="showEmailServerError"
          v-html="form.email.errors.invalid"
          class="form-group__hint"
        ></span>
      </div>
      <button
        type="submit"
        class="button button--primary"
        :disabled="!$v.form.email.$model.value"
      >
        <i class="icon-mail"></i>
        Send Invite
      </button>
    </form>
  </div>

  <span class="account-invite-friends__separator text-h4 account-invite-friends__title">or</span>

  <h2 class="text-h4 account-invite-friends__title">Copy the text below and share it with your contacts!</h2>
  <div class="account-invite-friends__box">
    <div class="account-invite-friends__box-heading">
      <h2 class="text-h4 account-invite-friends__title">
        You have been invited to join Hubbell’s Friends &amp; Family program.
        <br />
        Use the code below to get started.
      </h2>
      <p class="account-invite-friends__code-title">
        <span>Friends &amp; Family Code: </span>
        <span v-html="userData.employeeInviteFriendCode" class="account-invite-friends__code"></span>
      </p>
    </div>
    <div>
      <ol class="account-invite-friends__list">
        <li>
          <h2 class="text-h4 account-invite-friends__list-title">Register</h2>
          <ul class="account-invite-friends__sublist">
            <li>Visit <a href="https://store.hubbell.com/">store.hubbell.com</a> and create an account.</li>
            <li>Then proceed to My Account and open Account Overview.</li>
          </ul>
        </li>
        <li>
          <h2 class="text-h4 account-invite-friends__list-title">Redeem</h2>
          <ul class="account-invite-friends__sublist">
            <li>Simply copy the code above and enter it into the field to redeem.</li>
            <li>Once the code is submitted you will be able to shop as an official affiliate.</li>
          </ul>
        </li>
        <li>
          <h2 class="text-h4 account-invite-friends__list-title">Enjoy your shopping!</h2>
          <ul class="account-invite-friends__sublist">
            <li>Now that you’ve registered your code you can start receiving discounted pricing on Hubbell products from brands like
Progress Lighting and iDevices smart home devices!</li>
          </ul>
        </li>
      </ol>
    </div>
  </div>
  <div class="account-invite-friends__actions">
    <i v-if="showSuccessCopyIcon" class="icon-check account-invite-friends__success-icon"></i>
    <button
      class="button button--primary"
      @click="copyText"
    >
      <i class="icon-clone"></i>
      Copy text
    </button>
  </div>
  <loader v-if="isLoading"/>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { required, email } from 'vuelidate/lib/validators'
import FormValidationMixin from '@/mixins/FormValidationMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import FormGroup from '@/components/FormGroup'
import HintBar from '@/components/HintBar'

export default {
  name: 'AccountProfile',
  components: {
    FormGroup,
    HintBar
  },
  mixins: [FormValidationMixin, LoaderMixin],
  watch: {
    'form.email.value' () {
      this.showEmailServerError = false
      this.showEmailSentHint = false
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapGetters('user', ['profileCompletionPercentage', 'isEmployee']),
    ...mapGetters('app', ['isUSSite'])
  },
  methods: {
    ...mapActions('user', ['sendInvitationsEmail']),
    copyText () {
      const text = document.querySelector('.account-invite-friends__box')?.innerText
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text)
      this.toggleShowSuccessCopyIcon()
    },
    fallbackCopyTextToClipboard (text) {
      const textArea = document.createElement('textarea')
      textArea.value = text
      // Avoid scrolling to bottom
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)
    },
    toggleShowSuccessCopyIcon () {
      if (!this.showSuccessCopyIcon) {
        this.showSuccessCopyIcon = true
        setTimeout(() => {
          this.showSuccessCopyIcon = false
        }, 1500)
      }
    },
    sendInvite () {
      this.withPresendRoutine(() => {
        this.showEmailServerError = false
        this.showEmailSentHint = false

        const formData = {}
        this.pushFormToData(formData)
        this.withLoader(() => {
          return this.sendForm(this.sendInvitationsEmail, formData)
            .then(() => {
              this.form.email.value = ''
              setTimeout(() => { // Overrride value change in watcher
                this.showEmailSentHint = true
              }, 50)
            }).catch(() => {
              this.isValidationRun = true
              this.showEmailServerError = true
            })
        })
      })
    }
  },
  created () {
    if (!this.isUSSite || !this.isEmployee) this.$router.push('/')
  },
  validations () {
    return {
      form: {
        email: {
          value: {
            required,
            email,
            server: this.server
          }
        }
      }
    }
  },
  data () {
    return {
      isValidationRun: false,
      showSuccessCopyIcon: false,
      showEmailSentHint: false,
      showEmailServerError: false,
      form: {
        email: {
          placeholderText: 'Enter email address',
          value: '',
          defaultValue: '',
          title: 'email',
          type: 'email',
          disabled: false,
          variant: 'shadow',
          maxLength: '255',
          errors: {
            required: 'Please enter a Email Address',
            email: 'Please enter a valid Email Address',
            invalid: 'Invalid email address'
          }
        }
      },
      hintEmailSent: {
        variant: 'success',
        iconColor: 'success',
        message: 'Success! Your Invite has been sent'
      }
    }
  }
}
</script>
