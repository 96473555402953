<template>
  <div class="account-favorites" v-if="isInitialized && !showError">
    <div class="account-favorites__lists-line">
      <DropdownSelect ref="listDropdown" class="account-favorites__lists-dropdown">
        <template v-slot:label>
          <span class="dropdown-select__text">Lists / {{ activeList ? activeList.name : '' }}</span>
          <i class="icon-right-arrow dropdown-select__icon"></i>
        </template>
        <template v-slot:items>
          <li
            v-for="productsList in productsLists"
            :key="productsList.id"
            class="dropdown-select__item"
            :class="{ 'dropdown-select__item--disabled': productsList.id === activeList.id }"
            @click="productsList.id === activeList.id ? null : onProductListClick(productsList)"
          >
            <span class="dropdown-select__link">{{ productsList.name }}</span>
          </li>
        </template>
      </DropdownSelect>
      <div class="account-favorites__lists-line-right">
        <button
          type="button"
          class="button button--primary"
          @click="showCreateNewProductsListModal = true"
        >Create List
        </button>
        <div class="account-favorites__lists-line-actions">
          <DropdownSelect ref="actionsDropdown">
            <template v-slot:label>
              <i class="icon-ellipsis-v dropdown-select__icon account-favorites__ellipsis-icon"></i>
            </template>
            <template v-slot:items>
              <li
                v-if="!isFavoritesListActive"
                @click="showRenameProductsListModal = true"
                class="dropdown-select__item"
              >
                <span class="dropdown-select__link">Rename List</span>
              </li>
              <li
                @click="showDeleteAllProductsFromListModal = true"
                class="dropdown-select__item"
              >
                <span class="dropdown-select__link">Remove All Items</span>
              </li>
              <li
                v-if="!isFavoritesListActive"
                @click="showDeleteListModal = true"
                class="dropdown-select__item"
              >
                <span class="dropdown-select__link">Delete List</span>
              </li>
            </template>
          </DropdownSelect>
        </div>
      </div>
    </div>
    <div class="account-favorites__list">
      <CartProductsList
        v-if="activeList && activeList.products && activeList.products.length"
        :isListEditable="true"
        :isAddToCartVisible="true"
        :productsQty="activeList.products.length"
        class="order"
      >
        <template v-slot:cart-entry>
          <CartProduct
            v-for="productEntry in activeList.products"
            :key="productEntry.number"
            @addToCartRequested="onAddToCartClick"
            @navigateToProductDetails="setProductAnalyticsData({ referencePage: 'Favorites List' })"
            @quantityUpdated="onProductUpdate"
            @entryDeleteRequested="openDeleteProductModal"
            :cartEntry="productEntry"
            :isImageClickable="productEntry.product.isPurchasable"
          >
            <template v-slot:actions>
              <div v-if="!productEntry.product.isPurchasable" class="account-favorites__product-actions">
                <p>Item not available for purchase</p>
                <a
                  :href="getExternalPDPLink(productEntry.product.sku)"
                  target="_blank"
                  class="account-favorites__product-external-link link"
                >
                  View  item on hubbell.com
                  <i class="icon-external-link-alt" />
                </a>
              </div>
            </template>
          </CartProduct>
        </template>
        <template v-slot:loader>
          <loader v-if="isLoading"/>
        </template>
      </CartProductsList>
      <div class="account__body-inner account-favorites__noresults" v-else>
        <h3 class="text-h3">{{ emptyListMessage }}</h3>
      </div>
    </div>

    <ModalSimple
      :showModal="showDeleteProductModal"
      :title="deleteProductModalTitle"
      @confirm="onDeleteProductRequested"
      @reject="closeDeleteProductModal"
      @close="closeDeleteProductModal"
    />

    <ModalSimple
      :showModal="showDeleteListModal"
      :title="deleteListModalTitle"
      @confirm="onDeleteListRequested"
      @reject="showDeleteListModal = false"
      @close="showDeleteListModal = false"
      class="delete-list-modal"
    />

    <ModalSimple
      :showModal="showDeleteAllProductsFromListModal"
      :isLoading="isLoading"
      title="Are you sure you want to remove all items from this list?"
      @confirm="onDeleteAllProductsFromListRequested"
      @reject="showDeleteAllProductsFromListModal = false"
      @close="showDeleteAllProductsFromListModal = false"
      class="delete-list-modal"
    />

    <ModalCreateNewProductsList
      :showModal="showCreateNewProductsListModal"
      @close="showCreateNewProductsListModal = false"
      @listCreated="onListCreated"
    />

    <ModalRenameProductsList
      v-if="showRenameProductsListModal"
      :showModal="showRenameProductsListModal"
      :productsList="activeList"
      @close="showRenameProductsListModal = false"
      @listRenamed="showRenameProductsListModal = false"
    />

    <ModalCart
      v-if="showModalCart"
      @close="closeCartModal"
      @addProduct="onAddToCartFromModalCart"
      :isLoading="isModalCartLoading"
      :productEntry="productEntryData"
      :showModalCart="showModalCart"
    />

    <ModalInventory
      @close="closeInventoryModal"
      :inventoryData="inventoryData"
      :showModal="showModalInventory"
    />
  </div>
  <div v-else class="account__body-inner account-favorites__noresults">
    <h3 v-if="showError" class="text-h3">Something went wrong</h3>
    <loader v-else />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import { constructExternalPDPLink } from '@/helpers'
import LoaderMixin from '@/mixins/LoaderMixin'
import AddToCartMixin from '@/mixins/AddToCartMixin'
import CartProductsList from '@/components/cart/common/CartProductsList'
import CartProduct from '@/components/cart/common/CartProduct'
import ModalSimple from '@/components/modal/ModalSimple'
import ModalCreateNewProductsList from '@/components/modal/ModalCreateNewProductsList'
import ModalCart from '@/components/modal/ModalCart'
import ModalInventory from '@/components/modal/ModalInventory'
import ModalRenameProductsList from '@/components/modal/ModalRenameProductsList'
import DropdownSelect from '@/components/DropdownSelect'

export default {
  name: 'AccountFavorites',
  components: {
    CartProductsList,
    CartProduct,
    ModalSimple,
    ModalCart,
    ModalInventory,
    ModalCreateNewProductsList,
    ModalRenameProductsList,
    DropdownSelect
  },
  mixins: [LoaderMixin, AddToCartMixin],
  computed: {
    ...mapState('user', ['productsLists']),
    activeList () {
      return this.productsLists?.find(list => list.id === this.activeListId)
    },
    favoritesList () {
      return this.productsLists?.find(list => list.isFavorites)
    },
    isFavoritesListActive () {
      return this.activeList?.id === this.favoritesList?.id
    },
    deleteListModalTitle () {
      return `Are you sure you want to delete this list: "${this.activeList?.name}"`
    },
    deleteProductModalTitle () {
      return this.isFavoritesListActive
        ? `Confirm Favorite Removal: ${this.productToDelete?.title}`
        : `Confirm Removal: ${this.productToDelete?.title}`
    },
    emptyListMessage () {
      return this.isFavoritesListActive
        ? 'No favorite products added yet'
        : 'No item added to list yet'
    }
  },
  methods: {
    ...mapMutations('product', { setProductAnalyticsData: 'SET_PRODUCT_ANALYTICS_DATA' }),
    ...mapActions('user', [
      'setProductsLists',
      'deleteProductsList',
      'deleteProductFromList',
      'deleteAllProductsFromList',
      'updateProductInList'
    ]),
    onProductUpdate ({ entryNumber, qty }) {
      const payload = {
        listId: this.activeList.id,
        productCode: entryNumber,
        quantity: qty
      }
      this.withLoader(() => this.updateProductInList(payload))
    },
    openDeleteProductModal (entry) {
      const productToDelete = this.activeList.products.find(el => el.number === entry.entryNumber).product
      this.productToDelete = productToDelete
      this.showDeleteProductModal = true
    },
    async onDeleteProductRequested () {
      const payload = {
        listId: this.activeList.id,
        productCode: this.productToDelete?.sku
      }

      this.withLoader(() => this.deleteProductFromList(payload))
      this.closeDeleteProductModal()
    },
    async onDeleteAllProductsFromListRequested () {
      this.withLoader(async () => {
        this.showDeleteAllProductsFromListModal = false
        await this.deleteAllProductsFromList({ listId: this.activeList.id })
      })
    },
    closeDeleteProductModal () {
      this.productToDelete = null
      this.showDeleteProductModal = false
    },
    async onAddToCartClick (productData) {
      const entryToAdd = this.activeList.products.find(el => el.number === productData.entryNumber)
      await this.withLoader(() => this.addProductToCart({
        productData: entryToAdd.product,
        addToCartQty: entryToAdd.quantity,
        position: 0,
        pageName: 'Favorites List',
        searchQuery: ''
      }))
    },
    onAddToCartFromModalCart ({ productData, addToCartQty }) {
      this.isModalCartLoading = true
      return this.addProductToCart({
        productData,
        addToCartQty,
        position: 0,
        pageName: 'Related Products Add to Cart',
        searchQuery: ''
      }).finally(() => {
        this.isModalCartLoading = false
      })
    },
    onProductListClick (list) {
      this.activeListId = list.id
      this.$refs.listDropdown.toggleDropDown(false)
    },
    onListCreated (createdList) {
      this.activeListId = this.productsLists.find(list => list.id === createdList.id)?.id
      this.showCreateNewProductsListModal = false
    },
    async onDeleteListRequested () {
      this.withLoader(async () => {
        this.showDeleteListModal = false
        await this.deleteProductsList({ listId: this.activeList.id })
        this.activeListId = this.favoritesList.id
      })
    },
    getExternalPDPLink (sku) {
      return constructExternalPDPLink(sku)
    }
  },
  async created () {
    await this.setProductsLists()
    this.activeListId = this.favoritesList?.id
    this.isInitialized = true
  },
  data () {
    return {
      showDeleteProductModal: false,
      productToDelete: null,
      isInitialized: false,
      isModalCartLoading: false,
      showListsDropDown: false,
      activeListId: null,
      showDeleteListModal: false,
      showCreateNewProductsListModal: false,
      showRenameProductsListModal: false,
      showDeleteAllProductsFromListModal: false,
      showError: false
    }
  }
}
</script>
