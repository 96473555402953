<template>
  <section class="account" v-if="currentAccountSection && isInitialized">
    <div class="container">
      <div v-if="!isMobile" class="account__menu column is-one-quarter">
        <div class="account__menu-title text-h4">
          My Account
        </div>
        <ul class="account__menu-list">
          <li class="account__menu-item">
            <router-link
              to="/account/profile"
              class="account__menu-link"
              title="Account Overview"
            >
              <i class="icon-cog"></i> Account Overview
            </router-link>
          </li>
          <li class="account__menu-item">
            <router-link
              to="/account/address-book"
              class="account__menu-link"
              title="Address Book"
            >
              <i class="icon-map-marker-alt"></i> Address Book
            </router-link>
          </li>
          <li class="account__menu-item">
            <router-link
              to="/account/favorites"
              class="account__menu-link"
              title="Favorites &amp; Lists"
            >
              <i class="icon-heart"></i> Favorites &amp; Lists
            </router-link>
          </li>
          <li class="account__menu-item">
            <router-link
              to="/account/preferences"
              class="account__menu-link"
              title="Preference Center"
            >
              <i class="icon-check-circle-light"></i> Preference Center
            </router-link>
          </li>
          <li class="account__menu-item">
            <router-link
              to="/account/order-history"
              class="account__menu-link"
              title="Order History"
            >
              <i class="icon-shopping-cart"></i> Order History
            </router-link>
          </li>
          <li v-if="isEmployee && isUSSite" class="account__menu-item">
            <router-link
              to="/account/invite-friends-and-family"
              class="account__menu-link"
              title="Invite Friends &amp; Family"
            >
              <i class="icon-users-light"></i> Invite Friends &amp; Family
            </router-link>
          </li>
        </ul>
      </div>
      <div class="account__body column">
        <div class="account__body-title">
          {{ currentAccountSection.title }}
        </div>
        <div>
          <AccountPreferenceCenter
            v-if="$route.params.section === 'preferences'"
            ref="accountPreferenceCenter"
            :routeLeaveData="routeLeaveData"
            @pageLeaveCanceled="routeLeaveData = null"
            @navigationInitiated="$router.push(routeLeaveData)"
          />
          <component v-else :is="currentAccountSection.component" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import PageMixin from '@/mixins/PageMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import AccountProfile from '@/components/account/AccountProfile'
import AccountAddressBook from '@/components/account/AccountAddressBook'
import AccountOrderHistory from '@/components/account/AccountOrderHistory'
import AccountFavorites from '@/components/account/AccountFavorites'
import AccountPreferenceCenter from '@/components/account/AccountPreferenceCenter'
import AccountInviteFriends from '@/components/account/AccountInviteFriends'

export default {
  name: 'Account',
  components: {
    AccountOrderHistory,
    AccountAddressBook,
    AccountProfile,
    AccountFavorites,
    AccountPreferenceCenter,
    AccountInviteFriends
  },
  mixins: [PageMixin, ScreenWidthMixin],
  computed: {
    ...mapState('user', ['userData']),
    ...mapGetters('user', ['isGuest', 'isEmployee']),
    ...mapGetters('app', ['isUSSite']),
    currentAccountSection () {
      const sections = {
        profile: {
          component: AccountProfile,
          title: 'Account Overview'
        },
        'address-book': {
          component: AccountAddressBook,
          title: 'Address Book'
        },
        'order-history': {
          component: AccountOrderHistory,
          title: 'Order History'
        },
        favorites: {
          component: AccountFavorites,
          title: 'Favorites & Lists'
        },
        preferences: {
          component: AccountPreferenceCenter,
          title: 'Preference Center'
        },
        'invite-friends-and-family': {
          component: AccountInviteFriends,
          title: 'Invite Friends & Family'
        }
      }
      if ((!this.isEmployee && this.isUSSite) || !this.isUSSite) delete sections['invite-friends-and-family']
      return sections[this.$route.params.section]
    }
  },
  methods: {
    handleRouteLeave (to, from, next) {
      if (from.path === '/account/preferences') {
        if (this.$refs.accountPreferenceCenter.allowPageLeave) next()
        this.routeLeaveData = to.fullPath
      } else {
        next()
      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.handleRouteLeave(to, from, next)
  },
  beforeRouteLeave (to, from, next) {
    this.handleRouteLeave(to, from, next)
  },
  created () {
    if (!this.currentAccountSection) this.$router.push('/')
    else if (this.currentAccountSection && this.isGuest) this.$router.push('/404')
    else this.isInitialized = true
  },
  data () {
    return {
      isInitialized: false,
      routeLeaveData: null
    }
  }
}
</script>
