<template>
  <section class="account-orders" v-if="ordersData">
    <div class="account-orders__filters-block">
      <form
        v-on:submit.prevent="onQueryUpdate"
        class="header__search-form"
      >
        <input
          v-model="searchQuery"
          @input="onQueryUpdate"
          type="search"
          placeholder="Search by Order Reference Number"
          class="input"
        />
        <button type="submit" class="header__search-btn icon-search"></button>
      </form>
      <div class="account-orders__filters-line">
        <div class="account-orders__dropdown account-orders__dropdown--date">
          <span class="account-orders__dropdown-label">Order Date</span>
          <DropdownPopup
            ref="dateDropdown"
            :openBothSides="activeDateOption.id === 'customRange'"
            :twosided="true"
          >
            <template v-slot:active-value>{{ activeDateOption.dropdownTitle }}</template>
            <template v-slot:content-left>
              <ul class="dropdown-popup__options">
                <li
                  v-for="dateOption in dateOptions"
                  :key="dateOption.id"
                  @click="onDateOptionClick(dateOption)"
                  class="dropdown-popup__option"
                  :class="{ 'active': dateOption.id === activeDateOption.id }"
                >
                  {{ dateOption.optionTitle }}
                </li>
              </ul>
            </template>
            <template v-slot:content-right>
              <div>
                <DatePicker
                  v-model="customDateRangeValue"
                  :is-range="true"
                  color="gray"
                  @input="customDateChanged"
                />
              </div>
            </template>
          </DropdownPopup>
        </div>

        <div class="pagination__wrap" v-if="isPaginationVisible">
          <Paginate
            v-model="currentPage"
            :page-count="ordersResultsMetaData.totalPages"
            prev-text="Prev"
            next-text="Next"
            container-class="pagination"
            page-class="pagination__item"
            page-link-class="pagination__link"
            next-class="pagination__item"
            prev-class="pagination__item"
            next-link-class="pagination__link"
            prev-link-class="pagination__link"
          />
        </div>

        <div class="account-orders__dropdown account-orders__dropdown--sorting">
          <span class="account-orders__dropdown-label">Sort by</span>
          <DropdownPopup ref="sortingDropdown">
            <template v-slot:active-value>{{ `Order Date: ${activeSortingOption.optionTitle}` }}</template>
            <template v-slot:content-left>
              <ul class="dropdown-popup__options">
                <li
                  v-for="sortingOption in sortingOptions"
                  :key="sortingOption.id"
                  @click="onSortingOptionClick(sortingOption)"
                  class="dropdown-popup__option"
                  :class="{ 'active': sortingOption.id === activeSortingOption.id }"
                >
                  {{ sortingOption.optionTitle }}
                </li>
              </ul>
            </template>
            <template v-slot:content-right>
              <div>
                <p class="dropdown-popup__right-title">Select "from" date, then "to" date</p>
                <DatePicker
                  v-model="customDateRangeValue"
                  :is-range="true"
                  color="gray"
                  @input="customDateChanged"
                />
              </div>
            </template>
          </DropdownPopup>
        </div>
      </div>

    </div>

    <ul class="account-orders__list" v-if="ordersData.length">
      <li
        v-for="order in ordersData"
        :key="order.id"
        class="account-orders__item order"
      >
        <ul class="order__header">
          <li class="order__header-item">
            <div class="order__date">
              {{ formatDate(order.created) }}
            </div>
            <div class="order__number">Order Reference # {{ order.id }}</div>
          </li>
          <li class="order__header-item order__header-item--actions">
            <button
              @click="openModalReorder(order)"
              :disabled="!order.entries.some(entry => entry.product.isPurchasable)"
              class="order__link-details button button--primary"
            >Order Again</button>
            <router-link :to="order.link" class="order__link-details button button--primary">
              View order details
            </router-link>
          </li>
        </ul>
        <div class="order__body">
          <div class="order__total">{{ formatPrice(order.totalPriceWithTax) }}</div>
          <div class="order__status">
            <i class="icon" :class="getShippingStatus(order.status).iconClass"></i>
            <span>{{ getShippingStatus(order.status).title }}</span>
          </div>
          <ul class="order__product-list" v-if="order.entries && order.entries.length">
            <li
              v-for="productEntry in order.entries"
              :key="productEntry.number"
              class="order__product"
            >
              <a
                @click.prevent="handleProductLinkClick(productEntry.product.link)"
                :href="productEntry.product.link"
                class="order__product-link overlay"
              >
                <img
                  :src="productEntry.product.image.previewSrc"
                  :alt="productEntry.product.image.title"
                  loading="lazy"
                  class="order__product-img"
                />
              </a>
              <a
                v-if="isMobile"
                @click.prevent="handleProductLinkClick(productEntry.product.title)"
                :href="productEntry.product.title"
                v-html="productEntry.product.title"
                class="order__product-link order__product-title"
              >
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <div class="account__body-inner account-orders__no-result-wrap" v-else>
      <h2 class="text-h3">No Orders Yet</h2>
    </div>

    <div class="pagination__wrap" v-if="isPaginationVisible">
      <Paginate
        v-model="currentPage"
        :page-count="ordersResultsMetaData.totalPages"
        prev-text="Prev"
        next-text="Next"
        container-class="pagination"
        page-class="pagination__item"
        page-link-class="pagination__link"
        next-class="pagination__item"
        prev-class="pagination__item"
        next-link-class="pagination__link"
        prev-link-class="pagination__link"
      />
    </div>

    <ModalReorder
      v-if="showModalReorder"
      :orderData="reorderData"
      :showModal="showModalReorder"
      @close="closeModalReorder"
    />

    <loader v-if="isLoading" />
  </section>
  <div class="account__body-inner" v-else>
    <loader />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Paginate from 'vuejs-paginate'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import * as dayjs from 'dayjs'
import _ from 'lodash'

import LoaderMixin from '@/mixins/LoaderMixin'
import FormatMixin from '@/mixins/FormatMixin'
import ShippingStatusesMixin from '@/mixins/ShippingStatusesMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import NavigationalMixin from '@/mixins/NavigationalMixin'
import DropdownPopup from '@/components/DropdownPopup'
import ModalReorder from '@/components/modal/ModalReorder'

export default {
  name: 'AccountOrderHistory',
  mixins: [LoaderMixin, FormatMixin, ShippingStatusesMixin, ScreenWidthMixin, NavigationalMixin],
  components: {
    Paginate,
    DropdownPopup,
    DatePicker,
    ModalReorder
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('orders', ['ordersData', 'ordersResultsMetaData']),
    currentPage: {
      get () {
        return this.ordersResultsMetaData.currentPage + 1
      },
      set (selectedPage) {
        this.getOrders(selectedPage - 1)
      }
    },
    isPaginationVisible () {
      return this.ordersData.length && this.ordersResultsMetaData.totalPages > 1
    }
  },
  methods: {
    ...mapActions('orders', ['setOrdersData']),
    ...mapMutations('orders', { unsetOrdersData: 'UNSET_ORDERS_DATA', unsetOrdersResultsMetaData: 'UNSET_ORDERS_RESULTS_META_DATA' }),
    ...mapMutations('product', { setProductAnalyticsData: 'SET_PRODUCT_ANALYTICS_DATA' }),
    getOrders (page = 0) {
      if (!this.isLoading) {
        const filters = {
          sort: this.activeSortingOption.id,
          currentPage: page,
          pageSize: 10,
          fromDate: this.activeDateOption?.dates?.start || '',
          toDate: this.activeDateOption?.dates?.end || '',
          code: this.searchQuery
        }

        for (const filterName in filters) {
          if (!filters[filterName]) delete filters[filterName]
        }

        this.withLoader(() =>
          this.setOrdersData(filters)
        )
      }
    },
    handleProductLinkClick (link) {
      this.setProductAnalyticsData({ referencePage: 'Order History' })
      this.navigateTo(link)
    },
    onQueryUpdate: _.debounce(function () {
      this.getOrders()
    }, 1000),
    formatDate (date) {
      return dayjs(date).format('MM/DD/YYYY')
    },
    customDateChanged (range) {
      this.customDateRangeValue = range
      const dateOption = { ...this.getDateOptionById('customRange') }
      dateOption.dates = {
        start: dayjs(this.customDateRangeValue.start).startOf('day').valueOf(),
        end: dayjs(this.customDateRangeValue.end).endOf('day').valueOf()
      }
      dateOption.dropdownTitle = `${this.formatDate(dateOption.dates.start)} - ${this.formatDate(dateOption.dates.end)}`
      this.activeDateOption = dateOption
      this.$refs.dateDropdown.close()
    },
    onDateOptionClick (dateOption) {
      if (dateOption.id !== 'customRange') {
        this.activeDateOption = dateOption
        this.$refs.dateDropdown.close()
        this.customDateRangeValue = null
      } else {
        this.$refs.dateDropdown.toggleRightContentVisibility(true)
      }
    },
    getDateOptionById (id) {
      return this.dateOptions.find(dateOption => dateOption.id === id)
    },
    onSortingOptionClick (sortingOption) {
      this.$refs.sortingDropdown.close()
      this.activeSortingOption = sortingOption
    },
    openModalReorder (orderData) {
      this.reorderData = orderData
      this.showModalReorder = true
    },
    closeModalReorder () {
      this.reorderData = null
      this.showModalReorder = false
    }
  },
  async created () {
    this.unsetOrdersData()
    this.unsetOrdersResultsMetaData()
    this.activeDateOption = this.getDateOptionById('all')
    this.activeSortingOption = this.sortingOptions.find(sortingOption => sortingOption.id === 'byDateDesc')
    await this.getOrders()

    this.$watch('activeDateOption', () => {
      this.getOrders()
    })
    this.$watch('activeSortingOption', () => {
      this.getOrders()
    })
  },
  data () {
    return {
      searchQuery: '',
      customDateRangeValue: null,
      activeDateOption: null,
      activeSortingOption: null,
      reorderData: null,
      showModalReorder: false,
      dateOptions: [
        {
          id: 'today',
          optionTitle: 'Today',
          dropdownTitle: 'Today',
          dates: {
            start: dayjs().startOf('day').valueOf(),
            end: dayjs().valueOf()
          }
        },
        {
          id: '7days',
          optionTitle: 'Last 7 Days',
          dropdownTitle: 'Last 7 Days',
          dates: {
            start: dayjs().startOf('day').subtract(7, 'day').valueOf(),
            end: dayjs().valueOf()
          }
        },
        {
          id: 'customRange',
          optionTitle: 'Custom Range',
          dropdownTitle: '',
          dates: {
            start: '',
            end: ''
          }
        },
        {
          id: 'all',
          optionTitle: 'All',
          dropdownTitle: 'All',
          dates: {
            start: '',
            end: ''
          }
        }
      ],
      sortingOptions: [
        {
          id: 'byDateDesc',
          optionTitle: 'New to Old'
        },
        {
          id: 'byDateAsc',
          optionTitle: 'Old to New'
        }
      ]
    }
  }
}
</script>
