<template>
  <Modal
    :showModal="showModal"
    :isLoading="isLoading"
    @close="$emit('close')"
    class="modal-create-new-products-list"
  >
    <template v-slot:body>
      <div v-if="!showError" class="modal-create-new-products-list__content">
        <div class="modal-create-new-products-list__form-block">
          <div class="modal-create-new-products-list__header">
            <h2 class="text-h3 modal-create-new-products-list__title">List Name</h2>
          </div>
          <form class="modal-create-new-products-list__form" @submit.prevent="onSubmit">
            <div class="modal-create-new-products-list__form-fields">
              <FormGroup
                v-model.trim="$v.form.listName.$model.value"
                :field="$v.form.listName"
                :isValidationRun="isValidationRun"
              />
            </div>
            <button
              type="submit"
              class="button button--primary"
              :disabled="!form.listName.value"
            >
              Save
            </button>
          </form>
        </div>
      </div>
      <div v-else class="modal-create-new-products-list__success-block">
        <h2 class="text-h3">Something went wrong</h2>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import FormValidationMixin from '@/mixins/FormValidationMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import Modal from '@/components/modal/Modal'
import FormGroup from '@/components/FormGroup'

export default {
  name: 'ModalRenameProductsList',
  components: {
    Modal,
    FormGroup
  },
  props: {
    showModal: Boolean,
    productsList: {
      type: Object,
      required: true
    }
  },
  mixins: [FormValidationMixin, LoaderMixin],
  methods: {
    ...mapActions('user', ['renameProductsList']),
    async onSubmit () {
      this.withPresendRoutine(() => {
        this.showError = false

        this.withLoader(() => {
          return this.sendForm(this.renameProductsList, { listId: this.productsList.id, listName: this.form.listName.value })
            .then(() => {
              this.$emit('listRenamed')
            }).catch(() => {
              this.showError = true
              this.isValidationRun = true
            })
        })
      })
    }
  },
  validations () {
    return {
      form: {
        listName: {
          value: {
            required,
            server: this.server
          }
        }
      }
    }
  },
  created () {
    const inputMask = new Array(255).join('F')
    this.form.listName.mask = inputMask
    this.form.listName.value = this.productsList.name
  },
  data () {
    return {
      isValidationRun: false,
      showError: false,
      form: {
        listName: {
          inputType: 'the-mask',
          mask: '',
          tokens: {
            F: {
              pattern: /[0-9a-zA-Z-\s]/
            }
          },
          placeholderText: 'Enter list name',
          value: '',
          defaultValue: '',
          title: 'listName',
          type: 'text',
          disabled: false,
          variant: 'shadow',
          maxLength: '255',
          errors: {
            required: 'Please enter a List name'
          }
        }
      }
    }
  }
}
</script>
