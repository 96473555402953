<template>
  <ModalAddressValidation
    v-if="showModalAddressValidation"
    @close="closedAddressValidationModal"
    @confirm="updateShippingAddressWithSuggested"
    :showModal="showModalAddressValidation"
    :suggestedData="suggestedData"
    :enteredData="enteredData"
    :isLoading="showModalAddressValidationLoader"
  />
  <Modal
    v-else
    @close="$emit('close')"
    :showModal="showModal"
    :isLoading="isLoading"
    class="modal-edit-address"
  >
    <template v-slot:header>
      <div>
        Update Address
      </div>
    </template>
    <template v-slot:body>
      <HintBar
        v-if="isAddressSaved"
        :hintData="hintAddressSaved"
      />
      <form class="address-form" @submit.prevent="updateAddress">
        <AddressForm
          @fieldChanged='onFieldChange'
          :form="form"
          :$v="$v"
          :isValidationRun="isValidationRun"
          class="address-form__fields"
        />

        <button
          :disabled="isLoading"
          type="submit"
          class="address-form__submit button button--primary"
        >Update Address</button>
      </form>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'

import usStates from '@/data/us-states'
import AddressFormMixin from '@/mixins/AddressFormMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import Modal from '@/components/modal/Modal'
import AddressForm from '@/components/account/AddressForm'
import HintBar from '@/components/HintBar'
import ModalAddressValidation from '@/components/modal/ModalAddressValidation'

export default {
  name: 'ModalEditAddress',
  components: {
    Modal,
    AddressForm,
    HintBar,
    ModalAddressValidation
  },
  mixins: [LoaderMixin, AddressFormMixin],
  props: ['address', 'showModal'],
  methods: {
    ...mapActions('user', ['updateShippingAddress', 'validateShippingAddress']),
    updateAddress () {
      this.withPresendRoutine(async () => {
        this.isAddressSaved = false
        const formData = {}
        this.pushFormToData(formData)

        try {
          const suggestedAddress = await this.sendForm(this.validateShippingAddress, formData)
          setTimeout(() => { // Hack to keep loader
            if (this.isTwoAddressesEqualForValidation(formData, suggestedAddress)) {
              this.sendForm(this.updateShippingAddress, formData)
                .then(() => {
                  this.isAddressSaved = true
                })
            } else {
              this.enteredData = formData
              this.suggestedData = suggestedAddress
              this.showModalAddressValidation = true
              return Promise.resolve()
            }
          }, 1)
        } catch (error) {
          this.isValidationRun = true
          throw error
        }
      })
    },
    closedAddressValidationModal () {
      this.showModalAddressValidation = false
      this.enteredData = {}
      this.suggestedData = {}
    },
    updateShippingAddressWithSuggested () {
      this.isAddressSaved = false
      this.showModalAddressValidationLoader = true
      this.sendForm(this.updateShippingAddress, { ...this.address, ...this.suggestedData })
        .then(() => {
          this.isAddressSaved = true
          const addressToForm = { ...this.suggestedData }
          addressToForm.region = usStates.find(state => state.isocode === this.suggestedData.region?.isocode) // Validation request returns incorrect region name
          this.pushDataToForm(addressToForm)
        }).catch(error => {
          this.isValidationRun = true
          throw error
        }).finally(() => {
          this.showModalAddressValidationLoader = false
          this.closedAddressValidationModal()
        })
    }
  },
  mounted () {
    this.pushDataToForm(this.address)
  },
  data () {
    return {
      isAddressSaved: false,
      isLoading: false,
      showModalAddressValidation: false,
      showModalAddressValidationLoader: false,
      enteredData: {},
      suggestedData: {},
      hintAddressSaved: {
        variant: 'success',
        iconColor: 'success',
        message: 'Your profile has been updated.'
      }
    }
  }
}
</script>
