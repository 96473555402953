<template>
  <div class="account__address-book address-book account__body-inner">
    <HintBar
      v-if="isAddressSaved"
      :hintData="hintAddressSaved"
      class="address-book__hintbar"
    />
    <div class="address-book__section" v-if="shippingAddressData && visibleAddresses.length">
      <h2 class="text-h3">My Addresses</h2>
      <div class="address-book__address-list">
        <div
          v-for="address in visibleAddresses"
          :key="address.id"
          class="address-book__address-item address"
          :class="{'address--active': address.defaultAddress}"
        >
          <div class="address__inner">
            <AddressItem :data="address" />
            <ul class="address__buttons-list">
              <li class="address__buttons-item">
                <button class="address__button" @click="openEditAddressModal(address)">Edit</button>
              </li>
              <li class="address__buttons-item" v-if="!address.defaultAddress">
                <button class="address__button" @click="setDefaultAddress(address)">Make Default</button>
              </li>
              <li class="address__buttons-item">
                <button class="address__button" @click="openDeleteAddressModal(address)">Remove</button>
              </li>
              <li class="address__buttons-item address__buttons-item--star" v-if="address.defaultAddress">
                <i class="icon-star"></i>
                <span>Default Address</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <button
        class="address-book__view-all-addresses button button--outlined button--medium"
        v-if="shippingAddressData.length > visibleAddresses.length"
        @click="isAllAddressesVisible = true"
      >View All</button>
    </div>
    <div class="address-book__section">
      <h2 class="text-h3">Add New Address</h2>
      <form class="address-form" ref="addNewAddressForm" @submit.prevent="saveNewAddress">
        <AddressForm
          @fieldChanged='onFieldChange'
          :form="form"
          :$v="$v"
          :isValidationRun="isValidationRun"
          class="address-form__fields"
        />

        <button
          :disabled="isLoading"
          type="submit"
          class="address-form__submit button button--primary"
        >Add Address</button>
      </form>
    </div>

    <ModalEditAddress
      v-if="showEditAddressModal"
      @close="closeEditAddressModal"
      :showModal="showEditAddressModal"
      :address="activeEditAddress"
    />
    <ModalAddressValidation
      @close="closedAddressValidationModal"
      @confirm="addSuggestedShippingAddress"
      :showModal="showModalAddress"
      :suggestedData="suggestedData"
      :enteredData="enteredData"
      :isLoading="showModalAddressValidationLoader"
    />

    <Modal
      @close="closeDeleteAddressModal"
      :showModal="showDeleteAddressModal"
      :isLoading="showDeleteAddressLoader"
      class="address-book__confirmation-modal"
    >
      <template v-slot:body>
        <h3 class="text-h3">Are you sure you want to remove this address?</h3>
      </template>
      <template v-slot:footer>
        <div class="modal__actions">
          <button
            @click="closeDeleteAddressModal"
            type="button"
            class="button button--outlined"
          >Cancel
          </button>
          <button
            @click="deleteSelectedAddress"
            type="button"
            class="button button--primary"
          >Confirm
          </button>
        </div>
      </template>
    </Modal>
    <loader v-if="isLoading"/>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import LoaderMixin from '@/mixins/LoaderMixin'
import AddressFormMixin from '@/mixins/AddressFormMixin'
import AddressItem from '@/components/AddressItem'
import Modal from '@/components/modal/Modal'
import AddressForm from '@/components/account/AddressForm'
import ModalEditAddress from '@/components/modal/ModalEditAddress'
import ModalAddressValidation from '@/components/modal/ModalAddressValidation'
import HintBar from '@/components/HintBar'

export default {
  name: 'AccountAddressBook',
  components: {
    AddressItem,
    Modal,
    AddressForm,
    ModalEditAddress,
    ModalAddressValidation,
    HintBar
  },
  mixins: [LoaderMixin, AddressFormMixin],
  computed: {
    ...mapState('user', ['userData', 'shippingAddressData']),
    visibleAddresses () {
      return this.shippingAddressData && this.shippingAddressData.length > 6 && !this.isAllAddressesVisible
        ? this.shippingAddressData.slice(0, 6)
        : [...this.shippingAddressData]
    }
  },
  methods: {
    ...mapActions('user', [
      'addShippingAddress',
      'deleteShippingAddress',
      'updateShippingAddress',
      'validateShippingAddress']),
    openEditAddressModal (address) {
      this.activeEditAddress = { ...address }
      this.showEditAddressModal = true
    },
    closedAddressValidationModal () {
      this.showModalAddress = false
      this.enteredData = {}
      this.suggestedData = {}
    },
    closeEditAddressModal () {
      this.activeEditAddress = null
      this.showEditAddressModal = false
    },
    openDeleteAddressModal (address) {
      this.activeDeleteAddress = { ...address }
      this.showDeleteAddressModal = true
    },
    closeDeleteAddressModal () {
      this.activeDeleteAddress = null
      this.showDeleteAddressModal = false
    },
    setDefaultAddress (address) {
      return this.withLoader(() => this.updateShippingAddress({
        ...address,
        defaultAddress: true
      }))
    },
    deleteSelectedAddress () {
      this.showDeleteAddressLoader = true
      return this.deleteShippingAddress(this.activeDeleteAddress.id)
        .then(() => {
          this.closeDeleteAddressModal()
        }).finally(() => {
          this.showDeleteAddressLoader = false
        })
    },
    addSuggestedShippingAddress (data) {
      this.showModalAddressValidationLoader = true
      this.addShippingAddress(data)
        .then(() => {
          this.isAddressSaved = true
          this.setAddressFormToDefault()
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }).catch(error => {
          this.isValidationRun = true
          throw error
        }).finally(() => {
          this.showModalAddressValidationLoader = false
          this.closedAddressValidationModal()
        })
    },
    saveNewAddress () {
      this.withPresendRoutine(() => {
        const formData = {}
        this.pushFormToData(formData)

        this.sendForm(this.validateShippingAddress, formData)
          .then((res) => {
            if (this.isTwoAddressesEqualForValidation(formData, res)) {
              return this.addSuggestedShippingAddress(formData)
            } else {
              this.enteredData = formData
              this.suggestedData = res
              this.showModalAddress = true
              return Promise.resolve()
            }
          }).catch(error => {
            this.isValidationRun = true
            throw error
          })
      })
    }
  },
  data () {
    return {
      enteredData: {},
      suggestedData: {},
      isAllAddressesVisible: false,
      showEditAddressModal: false,
      activeEditAddress: null,
      showDeleteAddressModal: false,
      isAddressSaved: false,
      showModalAddressValidationLoader: false,
      showDeleteAddressLoader: false,
      showModalAddress: false,
      hintAddressSaved: {
        variant: 'success',
        iconColor: 'success',
        message: 'Your profile has been updated.'
      }
    }
  }
}
</script>
