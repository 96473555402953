<template>
  <div
    v-click-outside="() => toggleDropDown(false)"
    class="dropdown-select"
  >
    <div
      @click="toggleDropDown"
      class="dropdown-select__btn"
    >
      <slot name="label"></slot>
    </div>
    <ul v-if="showDropDown" class="dropdown-select__list">
      <slot name="items"></slot>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    toggleDropDown (val) {
      this.showDropDown = typeof val === 'boolean' ? val : !this.showDropDown
    }
  },
  data () {
    return {
      showDropDown: false
    }
  }
}
</script>
