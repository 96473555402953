<template>
  <div
    class="dropdown-popup"
    :class="{ 'dropdown-popup--opened': showLeftContent, 'dropdown-popup--twosided': twosided }"
  >
    <div class="dropdown-popup__container">
      <div class="dropdown-popup__drawer">
        <div class="dropdown-popup__drawer-left">
          <div
            @click="toggle"
            class="dropdown-popup__active-value"
            :class="{ 'dropdown-popup__active-value--opened': showLeftContent }"
          >
            <slot name="active-value"></slot>
            <i class="icon-down-arrow"></i>
          </div>
          <div
            v-if="showLeftContent"
            class="dropdown-popup__content-left"
          >
            <slot name="content-left"></slot>
          </div>
        </div>
        <div v-if="showRightContent" class="dropdown-popup__drawer-right">
          <div class="dropdown-popup__content-right">
            <slot name="content-right"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    openBothSides: {
      type: Boolean,
      default: false
    },
    twosided: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle () {
      if (this.showLeftContent) {
        this.close()
      } else {
        this.toggleLeftContentVisibility(true)
        if (this.openBothSides) this.toggleRightContentVisibility(true)
      }
    },
    close () {
      this.toggleLeftContentVisibility(false)
      this.toggleRightContentVisibility(false)
    },
    toggleLeftContentVisibility (value) {
      this.showLeftContent = typeof value === 'boolean' ? value : !this.showLeftContent
    },
    toggleRightContentVisibility (value) {
      this.showRightContent = typeof value === 'boolean' ? value : !this.showRightContent
    }
  },
  data () {
    return {
      showLeftContent: false,
      showRightContent: false
    }
  }
}
</script>
