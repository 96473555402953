<template>
  <section class="account-preference-center account__body-inner">
    <template v-if="isInitialized">
      <h2 class="text-h4 account-preference-center__title">I would like to receive Hubbell announcements that regard to the following:</h2>
      <form class="account-preference-center__form" @submit.prevent="submitForm">
        <div class="account-preference-center__form-field">
          <FormCheckbox
            @input="onValueChange"
            class="account-preference-center__form-checkbox"
            v-model="form.newProductAnnouncements.value"
            :field="form.newProductAnnouncements"
          />
        </div>
        <div class="account-preference-center__form-field">
          <FormCheckbox
            @input="onValueChange"
            class="account-preference-center__form-checkbox"
            v-model="form.salesAndDiscounts.value"
            :field="form.salesAndDiscounts"
          />
        </div>
        <div class="account-preference-center__form-field account-preference-center__form-field--bottom">
          <FormCheckbox
            @input="clearValues"
            class="cart-summary__terms"
            v-model="form.unsubscribe.value"
            :field="form.unsubscribe"
          />
        </div>
        <div class="account-preference-center__actions">
          <span
            v-if="showSuccessMessage"
            class="account-preference-center__success-message"
          >
            Thank you! Your subscription preferences have been updated
          </span>
          <button
            type="submit"
            class="button button--primary account-profile__btn-save account-preference-center__btn-save"
            :disabled="isLoading || !isSomeValueChanged"
          >
            Save
          </button>
        </div>
      </form>
    </template>
    <loader v-if="!isInitialized || isLoading"/>

    <Modal
      @close="$emit('pageLeaveCanceled')"
      :showModal="!!routeLeaveData"
      class="address-book__confirmation-modal"
    >
      <template v-slot:body>
        <h3 class="text-h3">
          You have unsaved changes to your Preference Center. <br />
          Are you sure you would like to continue without saving?
        </h3>
      </template>
      <template v-slot:footer>
        <div class="modal__actions">
          <button
            @click="$emit('pageLeaveCanceled')"
            type="button"
            class="button button--primary"
          >Cancel
          </button>
          <button
            @click="$emit('navigationInitiated')"
            type="button"
            class="button button--primary"
          >Continue
          </button>
        </div>
      </template>
    </Modal>
  </section>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import emailSubscriptionsMap from '@/data/email-subscriptions-map'
import LoaderMixin from '@/mixins/LoaderMixin'
import FormValidationMixin from '@/mixins/FormValidationMixin'
import FormCheckbox from '@/components/FormCheckbox'
import Modal from '@/components/modal/Modal'

export default {
  name: 'AccountPreferenceCenter',
  components: {
    FormCheckbox,
    Modal
  },
  mixins: [LoaderMixin, FormValidationMixin],
  props: {
    routeLeaveData: {
      required: true
    }
  },
  computed: {
    ...mapState('user', ['userData', 'emailSubscriptionsData']),
    isSomeValueChanged () {
      return this.isInitialized
        ? Object.values(this.form).some(el => el.value !== this.emailSubscriptionsData?.[el.title])
        : false
    },
    allowPageLeave () {
      return this.isSomeValueChanged ? !!this.routeLeaveData : true
    }
  },
  methods: {
    ...mapActions('user', ['setEmailSubscriptionsData', 'updateEmailSubscriptionsData']),
    ...mapMutations('user', { setEmailSubscriptionDataLocal: 'SET_EMAIL_SUBSCRIPTIONS_DATA' }),
    onValueChange () {
      if (this.form.unsubscribe.value) this.form.unsubscribe.value = false
    },
    clearValues () {
      if (this.form.unsubscribe.value) {
        Object.values(this.form).forEach(el => {
          if (el.value && el.title !== 'unsubscribe') el.value = false
        })
      }
    },
    submitForm () {
      const requestData = {
        pageName: 'Preferences | Hubbell Store',
        pageUri: `${window.location.origin}/account/preferences`,
        newProductAnnouncements: this.form.newProductAnnouncements.value,
        salesAndDiscounts: this.form.salesAndDiscounts.value
      }
      this.withLoader(() => {
        return this.sendForm(this.updateEmailSubscriptionsData, requestData)
          .then(() => {
            const formData = {}
            this.pushFormToData(formData)
            this.setEmailSubscriptionDataLocal(formData)
            this.showSuccessMessage = true
          }).catch(() => {
            this.isValidationRun = true
          })
      })
    }
  },
  async created () {
    await this.setEmailSubscriptionsData()
    this.pushDataToForm(this.emailSubscriptionsData)
    this.isInitialized = true
  },
  data () {
    return {
      isValidationRun: false,
      showSuccessMessage: false,
      isInitialized: false,
      form: {
        newProductAnnouncements: {
          title: 'newProductAnnouncements',
          type: 'checkbox',
          label: emailSubscriptionsMap.newProductAnnouncements?.label,
          variant: 'shadow',
          value: false
        },
        salesAndDiscounts: {
          title: 'salesAndDiscounts',
          type: 'checkbox',
          label: emailSubscriptionsMap.salesAndDiscounts?.label,
          variant: 'shadow',
          value: false
        },
        unsubscribe: {
          title: 'unsubscribe',
          type: 'checkbox',
          label: emailSubscriptionsMap.unsubscribe?.label,
          variant: 'shadow',
          value: false
        }
      }
    }
  }
}
</script>
